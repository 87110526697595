import reject from 'lodash.reject'

export const state = () => ({
  messages: []
})

var count = 0;

export const mutations = {
  // add a notification
  add(state, message) {
    state.messages = [
      ...state.messages,
      message
    ]
  },

  // clear a notification
  remove(state, id) {
    state.messages = reject(state.messages, { id })
  },

  // clear all notifications
  reset(state) {
    state.messages = []
  }
}

export const actions = {
  // add a notification
  addMessage({ commit }, { type, code, message }) {
    commit('add', { id:count++, type, code, message })
  },

  // clear a notification
  clearMessage({ commit }, id) {
    commit('remove', id)
  },

  // clear all notifications
  clearAll({ commit }) {
    commit('reset')
  },

  // handle API errors
  apiError({ dispatch }, { graphQLErrors, message }) {
    if (graphQLErrors && graphQLErrors.length > 0) {
      graphQLErrors.forEach(({ message }) => {
        dispatch('addMessage', { type: 'error', message })
      })
    } else {
      dispatch('addMessage', { type: 'error', message })
    }
  }
}
