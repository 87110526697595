import { render, staticRenderFns } from "./mini-channel-link.vue?vue&type=template&id=cfb4ad60&scoped=true&lang=pug&"
import script from "./mini-channel-link.vue?vue&type=script&lang=coffee&"
export * from "./mini-channel-link.vue?vue&type=script&lang=coffee&"
import style0 from "./mini-channel-link.vue?vue&type=style&index=0&id=cfb4ad60&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfb4ad60",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChannelIcon: require('/opt/buildhome/repo/components/channels/channel-icon.vue').default})
