
import { mapState } from 'vuex'

export default
  computed:{
    ...mapState     'notifications', ['messages']
  }
  methods:
    clearMessage: (id)->
      @$store.dispatch 'notifications/clearMessage', id

