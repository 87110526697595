
import _includes                     from 'lodash.includes'
import { mapGetters }                from 'vuex'
import { ALL_CHANNELS, GET_CHANNEL } from '~/queries/channel'

export default
  data: ->
    section: 'main'

  apollo:
    channels:
      query: ALL_CHANNELS
      update: (data) -> data.allChannels.items

  computed: {
    mapGetters('session', ['orgTokenData'])...

    ready: ->
      @channels != undefined

    orgId: ->
      @$route.params.orgId

    isManagement: ->
      _includes ['owner', 'manager'], @orgTokenData.role

    isOwner: ->
      @orgTokenData.role == 'owner'
  }

