import ApolloClient from 'apollo-boost'
import Vue from 'vue'
import VueApollo from 'vue-apollo'

Vue.use VueApollo

export default ({ app, $config, store }) ->
  # initialize a default client
  apolloClient = new ApolloClient
    uri: $config.apiURL
    # set the auth token in the header if we have one
    request: (operation) ->
      # fetch the token from the store
      token = store.getters['session/token']

      if token != ""
        operation.setContext
          headers:
            Authorization: "Bearer #{token}"

  # initialize the apollo provider
  apolloProvider = new VueApollo
    defaultClient: apolloClient
    
  # add the provider to the app
  app.apolloProvider = apolloProvider
