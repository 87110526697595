
import _isEmpty from 'lodash.isempty'

export default
  props:
    channel:
      type: Object
      default:
        id          : ''
        description : ''
        name        : ''
        number      : ''

  data: ->
    isOpen : false

  computed:
    orgId       : -> @$route.params.orgId
    channelId   : -> @$route.params.channelId
    CTA         : -> if @isOpen then "Close" else "Manage"
    channelPath : -> "/orgs/#{ @orgId }/channels/#{ @channel.id }"
    canCall     : -> not _isEmpty @channel.number

  created: ->
    @syncToggle()

  watch:
    channelId: ->
      @syncToggle()

  methods:
    syncToggle: ->
      @isOpen = @channelId and @channelId == @channel.id

    toggle: ->
      if @isOpen then @close() else @open()

    open: ->
      @isOpen = true
      @$router.push "#{@channelPath}/general"

    close: ->
      @isOpen = false
      @$router.push "/orgs/#{ @orgId }/user-settings"

