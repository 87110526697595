import { render, staticRenderFns } from "./settings-nav.vue?vue&type=template&id=11515b9c&scoped=true&lang=pug&"
import script from "./settings-nav.vue?vue&type=script&lang=coffee&"
export * from "./settings-nav.vue?vue&type=script&lang=coffee&"
import style0 from "./settings-nav.vue?vue&type=style&index=0&id=11515b9c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11515b9c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeBtn: require('/opt/buildhome/repo/components/nav/home-btn.vue').default,MiniChannelLink: require('/opt/buildhome/repo/components/nav/mini-channel-link.vue').default,AddChannel: require('/opt/buildhome/repo/components/nav/add-channel.vue').default})
