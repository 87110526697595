
import { mapGetters }            from 'vuex'
import { MY_MACROS, ORG_MACROS } from '~/queries/macro'
import { ALL_RULES }             from '~/queries/rule'

export default
  props:
    kind:{ type:String, default: "macros" } # macros | rules

  apollo:
    channelRules:
      query     : ALL_RULES
      update    : (data) -> data.allRules.items
      variables : -> { @channelId }
      skip      : -> @kind != 'rules'
    
    # myMacros:
    #   query  : MY_MACROS
    #   update : (data) -> data.allMacros.items
    #   skip   : -> @kind != 'macros'

    orgMacros:
      query     : ORG_MACROS
      update    : (data) -> data.allMacros.items
      variables : -> { @orgId }
      skip      : -> @kind != 'macros'

  computed: {
    mapGetters('session', ['orgId'])...

    channelId: ->
      @$route.params.channelId

    # myMacrosLength: ->
    #   if @myMacros? then @myMacros.length else 0
    # 
    orgMacrosLength: ->
      if @orgMacros? then @orgMacros.length else 0
    
    channelRulesLength: ->
      if @channelRules? then @channelRules.length else 0
  }

  methods:
    createNewLink: ->
      switch @kind
        when 'macros'
          "/orgs/#{@orgId}/macros/new"
        when 'rules'
          "/orgs/#{@orgId}/channels/#{@channelId}/rules/new"

